<template>
  <div
    :class="['popit-game', { 'm-finish': isGameFinished }]"
    :style="{ backgroundImage: `url(${background})` }"
  >
    <popit-item
      v-for="item in items"
      :key="item.id"
      :is-active="item.isActive"
      @click="() => onPopitClick(item.id)"
    />
  </div>
</template>

<script>
import PopitItem from "@/components/games/popit/PopitItem";
import audio from "@/assets/audio/pop.mp3";

export default {
  name: "PopitGame",
  components: { PopitItem },
  props: {
    items: {
      type: Array,
      required: true,
    },
    background: {
      type: String,
      required: true,
    },
    isGameFinished: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      audio,
    };
  },
  methods: {
    onPopitClick(id) {
      const elem = this.items.find((el) => el.id === id);
      if (elem.isActive) {
        elem.isActive = false;

        const audio = new Audio(this.audio);
        audio.play();
        this.$emit("change");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.popit-game {
  width: 796px;
  max-height: 656px;
  height: 100%;
  box-sizing: border-box;
  border: 10px solid $green;
  border-radius: 70px;
  background-repeat: no-repeat;
  background-size: 110%;
  background-position: center;
  overflow: hidden;
  display: grid;
  opacity: 1;
  grid-template-columns: repeat(6, 1fr);
  transition: border 0.5s, filter, 0.5s, background-image 0.5s,
    opacity 0.7s 0.7s, transform 0.7s 0.7s, border-radius 0.7s 0.7s;

  @media (max-width: 1150px) {
    border: 7px solid $green;
    border-radius: 52px;
    grid-template-columns: repeat(5, 1fr);
    max-width: 459px;
    max-height: 556px;
    background-size: 150%;
  }

  @media (max-width: 499px) {
    max-width: 359px;
    min-height: 440px;
    background-size: 200%;
    grid-template-columns: repeat(5, 1fr);
  }

  &.m-finish {
    opacity: 0;
    border-radius: 0;
    transform: scale(1.5);
    border: 10px solid transparent;
    filter: drop-shadow(0px 0px 23px rgba(0, 0, 0, 0.25));
  }
}
</style>
