export const TEST_RESULT_TYPES = ['auditory' ,'visual' ,'kinesthetic' ,'digital'];

export const TEST_QUESTIONS = [
	{
		question: 'Чему вы больше поверите?',
		answers: [
			{
				text: 'тому, что увидите своими глазами',
				code: 'visual',
			},
			{
				text: 'фактам',
				code: 'digital',
			},
			{
				text: 'собственным чувствам',
				code: 'kinesthetic',
			},
			{
				text: 'тому, в чем есть для вас смысл',
				code: 'auditory',
			},
		]
	},
	{
		question: 'Чему вы учитесь чаще всего?',
		answers: [
			{
				text: 'понимать какие-то вещи',
				code: 'digital',
			},
			{
				text: 'делать какие-то вещи',
				code: 'kinesthetic',
			},
			{
				text: 'слушать новое',
				code: 'auditory',
			},
			{
				text: 'видеть новые возможности',
				code: 'visual',
			},
		]
	},
	{
		question: 'Как вы принимаете важные решения?',
		answers: [
			{
				text: 'Основываясь на своих ощущениях',
				code: 'kinesthetic',
			},
			{
				text: 'Если будет звучать для меня убедительно',
				code: 'auditory',
			},
			{
				text: 'Опираясь на собственную точку зрения',
				code: 'visual',
			},
			{
				text: 'Опираюсь на факты и логику',
				code: 'digital',
			},
		]
	},
	{
		question: 'Какой человек скорее всего окажет на вас влияние?',
		answers: [
			{
				text: 'У кого приятный голос',
				code: 'auditory',
			},
			{
				text: 'Кто хорошо выглядит',
				code: 'visual',
			},
			{
				text: 'Кто говорит разумные вещи',
				code: 'digital',
			},
			{
				text: 'Кто пробуждает у меня хорошие чувства',
				code: 'kinesthetic',
			},
		]
	},
	{
		question: 'Какое из утверждений подходит вам больше всего?',
		answers: [
			{
				text: 'мне очень легко понять смысл новых фактов и данных',
				code: 'digital',
			},
			{
				text: 'я всегда обращаю внимание на звуки окружающей действительности',
				code: 'auditory',
			},
			{
				text: 'я хорошо чувствую одежду, облегающую мое тело',
				code: 'kinesthetic',
			},
			{
				text: 'я легко замечаю все краски мира',
				code: 'visual',
			},
		]
	},
	{
		question: 'Представляя своего друга, что вы вспомните в первую очередь?',
		answers: [
			{
				text: 'Как он выглядит',
				code: 'visual',
			},
			{
				text: 'Как звучит его голос',
				code: 'auditory',
			},
			{
				text: 'Как он говорит',
				code: 'digital',
			},
			{
				text: 'Свои ощущения по отношению к нему',
				code: 'kinesthetic',
			},
		]
	},
	{
		question: 'Что для вас предпочтительнее?',
		answers: [
			{
				text: 'услышать факты, о которых человек знает',
				code: 'digital',
			},
			{
				text: 'увидеть картины, которые человек нарисовал',
				code: 'visual',
			},
			{
				text: 'узнать о чувствах человека',
				code: 'kinesthetic',
			},
			{
				text: 'послушать звучание голоса, звуки происходящих событий',
				code: 'auditory',
			},
		]
	},
	{
		question: 'Как окружающие смогут узнать о ваших делах?',
		answers: [
			{
				text: 'по моим чувствам',
				code: 'kinesthetic',
			},
			{
				text: 'по моему внешнему виду',
				code: 'visual',
			},
			{
				text: 'им следует послушать, как я говорю',
				code: 'digital',
			},
			{
				text: 'по интонациям моего голоса',
				code: 'auditory',
			},
		]
	},
	{
		question: 'Какое утверждение о вас наиболее верно?',
		answers: [
			{
				text: 'обычно я хорошо чувствую настроения людей',
				code: 'kinesthetic',
			},
			{
				text: 'я могу нарисовать лица, различные подробности того, что я видел',
				code: 'visual',
			},
			{
				text: 'я знаю, что именно думают близкие люди по поводу тех или иных вопросов',
				code: 'digital',
			},
			{
				text: 'я хорошо различаю интонацию голосов моих друзей',
				code: 'auditory',
			},
		]
	},
	{
		question: 'Что вам легче всего сделать из нижеперечисленного?',
		answers: [
			{
				text: 'найти идеальную для себя громкость звучания магнитофона, телевизора',
				code: 'auditory',
			},
			{
				text: 'подобрать наиболее разумные, убедительные аргументы, относящиеся к интересующему вас предмету',
				code: 'digital',
			},
			{
				text: 'выбрать наиболее удобную мебель',
				code: 'kinesthetic',
			},
			{
				text: 'подобрать для одежды, интерьера и др. богатые цветовые комбинации',
				code: 'visual',
			},
		]
	},
];

export const TEST_RESULTS = {
	'auditory': {
		title: 'У вас аудиальный тип репрезентативной системы',
		text: 'Смотрите рекомендации по дизайну ванной комнаты от психолога для вашего типа',
	},
	'visual': {
		title: 'У вас визуальный тип репрезентативной системы',
		text: 'Смотрите рекомендации по дизайну ванной комнаты от психолога для вашего типа'
	},
	'kinesthetic': {
		title: 'У вас кинестетический тип репрезентативной системы',
		text: 'Смотрите рекомендации по дизайну ванной комнаты от психолога для вашего типа',
	},
	'digital': {
		title: 'У вас дигитальный тип репрезентативной системы',
		text: 'Смотрите рекомендации по дизайну ванной комнаты от психолога для вашего типа',
	},
};
