import {
  POPITS_COUNT,
  BACKGROUND_COUNT,
  BACKGROUNDS_FULL,
  IS_GAME_ALMOST_FINISH_COUNT,
} from "@/consts/popit";
import axios from "axios";

export const popitModule = {
  state: () => ({
    currentBackground: 1,
    items: [],
    isGameFinished: false,
    finishGameData: {},
  }),
  mutations: {
    setItems(state, payload) {
      state.items = payload;
    },
    setIsGameFinished(state, payload) {
      state.isGameFinished = payload;
    },
    setCurrentBackground(state, payload) {
      state.currentBackground = payload;
    },
    setFinishGameData(state, payload) {
      state.finishGameData = payload;
    },
  },
  getters: {
    isGameAlmostFinished(state) {
      const activeElements = state.items.filter((item) => item.isActive);
      return activeElements.length <= IS_GAME_ALMOST_FINISH_COUNT;
    },
    selectedBackground(state) {
      return {
        backgroundImage: `url(${
          BACKGROUNDS_FULL[state.currentBackground - 1]
        })`,
      };
    },
  },
  actions: {
    initGame({ commit }) {
      const items = [];

      for (let i = 0; i < POPITS_COUNT; i++) {
        items.push({
          id: i,
          isActive: true,
        });
      }

      commit("setItems", items);
    },
    restartGame({ commit, state }) {
      commit(
        "setItems",
        state.items.map((item) => {
          return {
            ...item,
            isActive: true,
          };
        })
      );
      commit("setIsGameFinished", false);
    },
    changeGame({ commit }, payload) {
      commit("setIsGameFinished", payload);
    },
    changeBackground({ commit, state }) {
      state.currentBackground < BACKGROUND_COUNT
        ? commit("setCurrentBackground", state.currentBackground + 1)
        : commit("setCurrentBackground", 1);
    },
    finishGame({ commit }) {
      return axios
        .post(
          "/attempts",
          {
            type: "popit",
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "access-control-allow-origin": "*",
            },
          }
        )
        .then((res) => commit("setFinishGameData", res.data.data))
        .catch((e) => console.error(e.response));
    },
  },
};
