<template>
  <div>
    <b-modal
      id="popit-win-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <span class="close-icon" @click="$bvModal.hide('popit-win-modal')">
        <img src="@/assets/svg/icons/close.svg" aria-label="close" />
      </span>

      <div class="d-flex flex-column align-items-center">
        <h1 class="title">Поздравляем, вы прошли игру!</h1>

        <p class="modal-text">
          Хотите спроектировать такую же <br />
          ванную комнату? <br />
          Переходите по ссылке
        </p>

        <a
          target="_blank"
          href="https://leroymerlin.ru/uslugi/proektirovanie-i-dizayn/sozdayte-vannuyu-komnatu-svoey-mechty/"
          class="button button-green"
        >
          спроектировать ванную комнату
        </a>

        <div class="d-flex align-items-center">
          <button class="button-link" @click="onRestartGameWithBackground">
            Выбрать другой дизайн
          </button>

          <button class="button-link" @click="onRestartGame">
            начать заново
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions("popitModule", ["restartGame", "changeBackground"]),
    onRestartGame() {
      this.restartGame();
      this.$bvModal.hide("popit-win-modal");
    },
    onRestartGameWithBackground() {
      this.restartGame();
      this.changeBackground();
      this.$bvModal.hide("popit-win-modal");
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 42px;
  text-align: center;
  margin-top: 85px;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  transition: 0.5s all;

  &:hover {
    opacity: 0.8;
  }
}

.modal-text {
  font-size: 24px;
  font-weight: 500 !important;
}

.button {
  width: 100%;
  max-width: 350px;
  margin-top: 30px;
}

.button-link {
  margin-top: 15px;
  margin-right: 30px;
  margin-bottom: 60px;

  &:last-child {
    margin-right: 0;
  }
}
</style>
