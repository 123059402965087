<template>
  <button
    @click="onPopitClick"
    :class="['popit-item', { 'm-disable': !isActive }]"
  ></button>
</template>

<script>
export default {
  name: "PopitItem",
  props: {
    id: {
      type: Number,
      default: 0,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onPopitClick() {
      this.$emit("click", this.id);
    },
  },
};
</script>

<style scoped lang="scss">
.popit-item {
  width: 100%;
  height: 100%;
  background-color: #44981c;
  background-image: url("@/assets/svg/games/popit.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none;
  opacity: 1;
  transition: opacity 0.2s, background-image 0.3s;

  &:active {
    background-image: url("@/assets/svg/games/popit-pushed.svg");
  }

  &.m-disable {
    cursor: default;
    opacity: 0;
  }
}
</style>
