<template>
  <div class="content-container">
    <div class="content">
      <h1 class="title">Тут будет страница "Мнение психолога"</h1>

      <!--
      В Sidebar уже предусмотрены якори для ссылок,
      просто подставьте нужные айдишники к нужным блокам
      -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'PsychologistOpinion',
  components: {
  }
}
</script>

<style scoped lang="scss">

</style>
