import Vue from "vue";
import Vuex from "vuex";
import { popitModule } from "./popitModule";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    popitModule: {
      namespaced: true,
      ...popitModule,
    },
  },
});
