import bg1 from "@/assets/img/popit/bg-1.png";
import bg2 from "@/assets/img/popit/bg-2.png";
import bg3 from "@/assets/img/popit/bg-3.png";
import bg4 from "@/assets/img/popit/bg-4.png";
import bg5 from "@/assets/img/popit/bg-5.png";
import bg6 from "@/assets/img/popit/bg-6.png";
import bg7 from "@/assets/img/popit/bg-7.png";
import bg8 from "@/assets/img/popit/bg-8.png";
import bg9 from "@/assets/img/popit/bg-9.png";

import fullBg1 from "@/assets/img/popit/full-bg-1.png";
import fullBg2 from "@/assets/img/popit/full-bg-2.png";
import fullBg3 from "@/assets/img/popit/full-bg-3.png";
import fullBg4 from "@/assets/img/popit/full-bg-4.png";
import fullBg5 from "@/assets/img/popit/full-bg-5.png";
import fullBg6 from "@/assets/img/popit/full-bg-6.png";
import fullBg7 from "@/assets/img/popit/full-bg-7.png";
import fullBg8 from "@/assets/img/popit/full-bg-8.png";
import fullBg9 from "@/assets/img/popit/full-bg-9.png";

export const POPITS_COUNT = 30;
export const BACKGROUND_COUNT = 9;
export const IS_GAME_ALMOST_FINISH_COUNT = 2;
export const BACKGROUNDS = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9];
export const BACKGROUNDS_FULL = [
  fullBg1,
  fullBg2,
  fullBg3,
  fullBg4,
  fullBg5,
  fullBg6,
  fullBg7,
  fullBg8,
  fullBg9,
];
